
import { defineComponent } from 'vue';
import Error from './components/Error/Error.vue';

export default defineComponent({
  name: 'PageNotFound',
  components: {
    Error,
  },
});
