import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")

  return (_openBlock(), _createBlock(_component_Error, {
    code: 404,
    title: "Page Not Found",
    body: "Sorry we can't find the page you're looking for"
  }))
}