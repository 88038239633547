
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import router from '@/router';

export default defineComponent({
  name: 'Error',
  components: {
    Button,
  },
  props: {
    code: {
      type: Number,
      default: 404,
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup() {
    function goHome(): void {
      router.push({name: 'Home'});
    }

    return {
      goHome,
    };
  },
});
